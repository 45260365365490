import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"

const Rules = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage()

  return (
    <Layout>
      <SEO title="Pravila privatnosti" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className="bcg bcg-about" />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter">
            History, Present, Future...
          </h2>
        ) : (
          <h2 className="about-header visibility-filter">
            Povijest, Sadašnjost, Budućnost…
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Privacy Policy</h2>
        ) : (
          <h2 className="about-header mobile-visible">Pravila privatnosti</h2>
        )}
      </div>

      {selectedLanguage === "EN" ? (
        <div className="about-content mobile-visible">
          <h4 className="rules-mobile-header">PRIVACY POLICY</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            1. General Information This application collects and processes your
            personal data in accordance with the General Data Protection
            Regulation (GDPR).
          </h4>
          <h4 className="rules-mobile-header">2. Types of Data We Collect</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            - Basic data: name, surname, email address
            <br />
            - Location data: GPS coordinates when approving documents
            <br />
            - Photos: photos taken through the application
            <br />- Usage data: activities within the application
          </h4>
          <h4 className="rules-mobile-header">3. Purpose of Data Processing</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            - Providing basic application functionality
            <br />
            - Document authenticity confirmation
            <br />
            - Location verification during approval
            <br />- Improving user experience
          </h4>
          <h4 className="rules-mobile-header">4. Legal Basis</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            - Contract execution
            <br />
            - Legal obligation
            <br />
            - Legitimate interest
            <br />- Your consent
          </h4>
          <h4 className="rules-mobile-header">5. Your Rights</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            You have the right to:
            <br />
            - Access your data
            <br />
            - Correct inaccurate data
            <br />
            - Delete your data
            <br />
            - Restrict processing
            <br />
            - Transfer data
            <br />- Withdraw consent
          </h4>
          <h4 className="rules-mobile-header">6. Retention Period</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            We keep your data only as long as necessary for the purposes for
            which it is processed.
          </h4>
          <h4 className="rules-mobile-header">7. Data Security</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            We have implemented technical and organizational measures to protect
            your data.
          </h4>
          <h4 className="rules-mobile-header">8. Contact</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            For all data protection inquiries, contact us at:
            it@osijek-koteks.hr
          </h4>
        </div>
      ) : (
        <div className="about-content mobile-visible">
          <h4 className="rules-mobile-header">PRAVILA PRIVATNOSTI</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            1. Opće informacije
            <br />
            Ova aplikacija prikuplja i obrađuje vaše osobne podatke u skladu s
            Općom uredbom o zaštiti podataka (GDPR).
          </h4>
          <h4 className="rules-mobile-header">
            2. Vrste podataka koje prikupljamo
          </h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            - Osnovni podaci: ime, prezime, email adresa
            <br />
            - Lokacijski podaci: GPS koordinate prilikom odobrenja dokumenata
            <br />
            - Fotografije: fotografije koje napravite kroz aplikaciju
            <br />- Podaci o korištenju: aktivnosti unutar aplikacije
          </h4>
          <h4 className="rules-mobile-header">3. Svrha obrade podataka</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            - Pružanje osnovnih funkcionalnosti aplikacije
            <br />
            - Potvrda autentičnosti dokumenata
            <br />
            - Verifikacija lokacije pri odobrenju
            <br />- Poboljšanje korisničkog iskustva
          </h4>
          <h4 className="rules-mobile-header">4. Pravna osnova</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            - Izvršenje ugovora
            <br />
            - Zakonska obveza
            <br />
            - Legitimni interes
            <br />- Vaša privola
          </h4>
          <h4 className="rules-mobile-header">5. Vaša prava</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Imate pravo:
            <br />
            - Pristupiti svojim podacima
            <br />
            - Ispraviti netočne podatke
            <br />
            - Izbrisati svoje podatke
            <br />
            - Ograničiti obradu
            <br />
            - Prenijeti podatke
            <br />- Povući privolu
          </h4>
          <h4 className="rules-mobile-header">6. Razdoblje čuvanja</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Vaše podatke čuvamo samo onoliko dugo koliko je potrebno za svrhe
            zbog kojih se obrađuju.
          </h4>
          <h4 className="rules-mobile-header">7. Sigurnost podataka</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Implementirali smo tehničke i organizacijske mjere za zaštitu vaših
            podataka.
          </h4>
          <h4 className="rules-mobile-header">8. Kontakt</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Za sva pitanja o zaštiti podataka kontaktirajte nas na:
            it@osijek-koteks.hr
          </h4>
        </div>
      )}

      {/* Desktop version - visibility filter */}
      {selectedLanguage === "EN" ? (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            <h4 className="rules-header">PRIVACY POLICY</h4>
            <h5>
              1. General Information
              <br />
              This application collects and processes your personal data in
              accordance with the General Data Protection Regulation (GDPR).
            </h5>
            <h4 className="rules-header">2. Types of Data We Collect</h4>
            <h5>
              - Basic data: name, surname, email address
              <br />
              - Location data: GPS coordinates when approving documents
              <br />
              - Photos: photos taken through the application
              <br />- Usage data: activities within the application
            </h5>
            <h4 className="rules-header">3. Purpose of Data Processing</h4>
            <h5>
              - Providing basic application functionality
              <br />
              - Document authenticity confirmation
              <br />
              - Location verification during approval
              <br />- Improving user experience
            </h5>
            <h4 className="rules-header">4. Legal Basis</h4>
            <h5>
              - Contract execution
              <br />
              - Legal obligation
              <br />
              - Legitimate interest
              <br />- Your consent
            </h5>
            <h4 className="rules-header">5. Your Rights</h4>
            <h5>
              You have the right to:
              <br />
              - Access your data
              <br />
              - Correct inaccurate data
              <br />
              - Delete your data
              <br />
              - Restrict processing
              <br />
              - Transfer data
              <br />- Withdraw consent
            </h5>
            <h4 className="rules-header">6. Retention Period</h4>
            <h5>
              We keep your data only as long as necessary for the purposes for
              which it is processed.
            </h5>
            <h4 className="rules-header">7. Data Security</h4>
            <h5>
              We have implemented technical and organizational measures to
              protect your data.
            </h5>
            <h4 className="rules-header">8. Contact</h4>
            <h5>
              For all data protection inquiries, contact us at:
              it@osijek-koteks.hr
            </h5>
          </h4>
        </div>
      ) : (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            <h4 className="rules-header">PRAVILA PRIVATNOSTI</h4>
            <h5>
              1. Opće informacije
              <br />
              Ova aplikacija prikuplja i obrađuje vaše osobne podatke u skladu s
              Općom uredbom o zaštiti podataka (GDPR).
            </h5>
            <h4 className="rules-header">2. Vrste podataka koje prikupljamo</h4>
            <h5>
              - Osnovni podaci: ime, prezime, email adresa
              <br />
              - Lokacijski podaci: GPS koordinate prilikom odobrenja dokumenata
              <br />
              - Fotografije: fotografije koje napravite kroz aplikaciju
              <br />- Podaci o korištenju: aktivnosti unutar aplikacije
            </h5>
            <h4 className="rules-header">3. Svrha obrade podataka</h4>
            <h5>
              - Pružanje osnovnih funkcionalnosti aplikacije
              <br />
              - Potvrda autentičnosti dokumenata
              <br />
              - Verifikacija lokacije pri odobrenju
              <br />- Poboljšanje korisničkog iskustva
            </h5>
            <h4 className="rules-header">4. Pravna osnova</h4>
            <h5>
              - Izvršenje ugovora
              <br />
              - Zakonska obveza
              <br />
              - Legitimni interes
              <br />- Vaša privola
            </h5>
            <h4 className="rules-header">5. Vaša prava</h4>
            <h5>
              Imate pravo:
              <br />
              - Pristupiti svojim podacima
              <br />
              - Ispraviti netočne podatke
              <br />
              - Izbrisati svoje podatke
              <br />
              - Ograničiti obradu
              <br />
              - Prenijeti podatke
              <br />- Povući privolu
            </h5>
            <h4 className="rules-header">6. Razdoblje čuvanja</h4>
            <h5>
              Vaše podatke čuvamo samo onoliko dugo koliko je potrebno za svrhe
              zbog kojih se obrađuju.
            </h5>
            <h4 className="rules-header">7. Sigurnost podataka</h4>
            <h5>
              Implementirali smo tehničke i organizacijske mjere za zaštitu
              vaših podataka.
            </h5>
            <h4 className="rules-header">8. Kontakt</h4>
            <h5>
              Za sva pitanja o zaštiti podataka kontaktirajte nas na:
              it@osijek-koteks.hr
            </h5>
          </h4>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "pp2.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Rules
